import Axios from "@/plugins/Axios";

export class PlanosSemanaisInfantil {
  async planoSemanal(gestaoDeAulaId, mes_id, semana_id) {
    const response = await Axios().get(
      `planos-semanais-infantil/plano-mensal/${gestaoDeAulaId}/${mes_id}/${semana_id}`
    );

    return response.data;
  }

  async createOrUpdate(plano) {
    const response = await Axios().post(`planos-semanais-infantil/criar-plano-mensal`, {
      plano,
    });
    return response.data;
  }

  async planoSemanalPDF(gestaoDeAulaId, mes_id, semana_id, file) {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await Axios().post(
        `planos-semanais-infantil/uploadPdf/${gestaoDeAulaId}/${mes_id}/${semana_id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const resultData = response.data ?? response;
      if (resultData) {
        return resultData;
      }
      return null;
    } catch (error) {
      throw error;
    }
  }

  async downloadPlanoSemanal(gestaoDeAulaId, mes_id, semana_id) {
    const response = await Axios()
      .get(`planos-semanais-infantil/downloadPdf/${gestaoDeAulaId}/${mes_id}/${semana_id}`, {
        responseType: "blob",
      })
      .then((response) => {
        const a = document.createElement("a");
        a.href = window.URL.createObjectURL(response.data);
        window.open(a.href);
      });

    return response;
  }
}

export default new PlanosSemanaisInfantil();
