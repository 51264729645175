<template>
  <v-row justify="center">
    <v-dialog v-model="localDialog" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="fecharModal">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Plano Bimestral</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-row>
          <v-col>
            <iframe :src="pdfAnexado" frameborder="0" style="min-height: 100vh; width: 100%">
            </iframe>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: {
    dialogPDF: {
      type: Boolean,
      required: true,
    },
    pdfAnexado: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      localDialog: false,
    };
  },
  watch: {
    dialogPDF: {
      immediate: true,
      handler(newVal) {
        this.localDialog = newVal;
      },
    },
  },
  methods: {
    fecharModal() {
      this.$emit("update:dialogPDF", false);
    },
  },
};
</script>
