<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn color="primary" x-small text @click="() => $router.go(-1)" :disabled="false">
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          Relatório Descritivo
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card v-if="turma && aluno">
          <v-card-title>Relatório</v-card-title>
          <v-card-text>
            <h4>Turma: {{ turma.descricao }}</h4>
            <h4>Aluno: {{ aluno.nomecompleto }}</h4>
          </v-card-text>
          <v-divider class="mx-4"></v-divider>
          <v-card-title>Professores (diários)</v-card-title>
          <v-card-text>
            <div v-for="diario in gestao_de_aulas" :key="diario.id">
              Professor(a): {{ diario.professor?.nome }} | Disciplina:
              {{ diario.disciplina?.descricao }}
            </div>
          </v-card-text>
          <v-spacer></v-spacer>
        </v-card>
      </v-col>
    </v-row>
    <v-tabs v-model="form.semestre" background-color="primary" grow>
      <v-tab :href="`#tab-${item.value}`" v-for="item of $constants.semestres" :key="item.value">
        <v-badge color="primary">
          {{ item.desc }}
        </v-badge>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="form.semestre">
      <v-tab-item
        v-for="item in $constants.semestres"
        :key="item.value"
        :value="'tab-' + item.value"
      >
        <v-card flat>
          <v-card-text>
            <form @submit.prevent="createOrUpdate">
              <v-col class="mb-6" cols="12">
                <e-label>Anexar Relatório Descritivo</e-label>
                <div class="dropboxStyles">
                  <v-row>
                    <v-col cols="9">
                      <v-file-input
                        v-model="formData[item.value].file"
                        accept="application/pdf"
                        dense
                        solo
                        label="Relatório Descritivo"
                        prepend-icon="mdi-file"
                        chip-color="red"
                        @change="onFileSelected($event, item.value)"
                      ></v-file-input>
                    </v-col>
                    <v-col cols="3" class="text-right" v-if="formData[item.value].path">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            @click="downloadRelatorioDescritivo(item.value)"
                            class="ml-2"
                            color="red lighten-5"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon color="red lighten-1" small>fa fa-file-pdf</v-icon>
                            <span class="ml-2">Baixar relatório</span>
                          </v-btn>
                        </template>
                        <span>Baixar relatório</span>
                      </v-tooltip>
                    </v-col>
                    <v-col cols="12" v-if="formData[item.value].path">
                      <v-alert dense color="green" icon="mdi-check-circle" text border="left">
                        Enviado: {{ formData[item.value].path }}
                      </v-alert>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
              <v-textarea
                rows="10"
                label="DESCRITIVO DO ALUNO."
                v-model="formData[item.value].descricao"
              ></v-textarea>
              <v-divider class="my-5"></v-divider>
              <v-textarea
                rows="2"
                label="A CRIANÇA DEMONSTROU:"
                v-model="formData[item.value].demonstrou"
              ></v-textarea>
              <v-btn color="primary" type="submit">Enviar Dados</v-btn>
            </form>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </main-template>
</template>

<script>
export default {
  async mounted() {
    await this.carrregarDescritivo(this.matricula_id);
  },
  data() {
    return {
      form: {
        semestre: 0,
      },
      formData: {
        1: {
          descricao: "",
          demonstrou: "",
          path: "",
          file: null,
        },
        2: {
          descricao: "",
          demonstrou: "",
          path: "",
          file: null,
        },
      },
      legendas: null,
      opcoes: null,
      sistemaBNCC: null,
      notas: null,
      aluno: null,
      matricula: null,
      turma: null,
      gestao_de_aulas: null,
      promiseCount: 0,
      tab_atual: 0,
      resumos: {},
      styleClass: [
        "red lighten-4",
        "yellow accent-1",
        "orange accent-1",
        "lime lighten-3",
        "light-green lighten-1",
      ],
      show: true,
    };
  },
  watch: {
    "form.semana_id": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadData();
      }
    },
  },
  created() {
    this.loadBasicData();
  },
  methods: {
    async createOrUpdate() {
      this.$loaderService.open("Carregando dados...");
      const { matricula_id } = this.$route.params;
      try {
        const payload = {
          1: this.formData[1] || {
            descricao: "",
            demonstrou: "",
            path: "",
          },
          2: this.formData[2] || {
            descricao: "",
            demonstrou: "",
            path: "",
          },
        };
        await this.$services.sistemaBNCCService.createOrUpdate(payload, matricula_id);
        this.$toast.success("Relatório Descritivo salvo com sucesso!");
      } catch (error) {
        this.$handleError(error);
      } finally {
        this.$loaderService.close();
      }
    },
    async carrregarDescritivo() {
      const { matricula_id } = this.$route.params;
      try {
        this.$loaderService.open("Carregando dados...");

        const response = await this.$services.sistemaBNCCService.getGestaoRelatorioDescritivo(
          matricula_id
        );

        if (response) {
          this.formData[1] =
            response.semestre_1 && response.semestre_1 !== "[]"
              ? JSON.parse(response.semestre_1)
              : {
                  descricao: "",
                  demonstrou: "",
                  path: "",
                  file: null,
                };
          this.formData[2] =
            response.semestre_2 && response.semestre_2 !== "[]"
              ? JSON.parse(response.semestre_2)
              : {
                  descricao: "",
                  demonstrou: "",
                  path: "",
                  file: null,
                };
        }
        this.$emit("dialogChange", !this.dialog);
      } catch (error) {
        this.$handleError(error);
      } finally {
        this.$loaderService.close();
      }
    },
    async loadBasicData() {
      try {
        this.$loaderService.open("Carregando dados...");
        const payload = await this.$services.sistemaBNCCService.syncAll(
          parseInt(this.$route.params.matricula_id, 10)
        );
        this.sistemaBNCC = payload.sistema.sistema_b_n_c_c.children;
        this.legendas = payload.sistema.sistema_b_n_c_c.legenda;
        this.opcoes = Object.keys(payload.sistema.sistema_b_n_c_c.opcoes);
        this.notas = payload.notas;
        this.aluno = payload.matricula.aluno;
        this.matricula = payload.matricula;
        this.turma = payload.matricula.turma;
        this.resumos = { ...payload.resumos, matricula_id: this.matricula.id };
        this.gestao_de_aulas = payload.matricula.turma.gestao_de_aulas;
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    async salvarNotas() {
      this.promiseCount += 1;
      try {
        await this.$services.sistemaBNCCService.salvarNotas(
          parseInt(this.$route.params.matricula_id, 10),
          { notas: this.notas, resumos: this.resumos }
        );
        this.$toast.info("Relatório Salvo...");
      } catch (error) {
        this.$handleError(error);
      }
      this.promiseCount -= 1;
      this.$router.back();
    },
    async onFileSelected(file, semestre) {
      if (file) {
        const { matricula_id } = this.$route.params;

        try {
          const response = await this.$services.sistemaBNCCService.relatorioDescritivoPDF(
            matricula_id,
            semestre,
            file
          );
          this.$set(this.formData[semestre], "path", response.path);
        } catch (error) {
          this.$toast.danger(error.error);
          this.$handleError(error);
        } finally {
          this.isUploading = false;
        }
      }
    },
    async downloadRelatorioDescritivo(semestre) {
      const { matricula_id } = this.$route.params;
      try {
        await this.$services.sistemaBNCCService.relatorioDescritivoDownload(matricula_id, semestre);
      } catch (error) {
        // console.error("Erro ao baixar o arquivo:", error);
      }
    },
  },
};
</script>

<style></style>
