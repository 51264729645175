import Axios from "@/plugins/Axios";

export class PlanosBimestraisService {
  async planoBimestral(gestaoDeAulaId, bimestre, disciplinaId) {
    const response = await Axios().get(
      `planos-bimestrais/plano-bimestral/${gestaoDeAulaId}/${bimestre}/${disciplinaId}`
    );
    return response.data;
  }

  async createOrUpdate(plano) {
    const response = await Axios().post(`planos-bimestrais/criar-plano-bimestral`, {
      plano,
    });
    return response.data;
  }

  async planoBimestralPDF(gestaoDeAulaId, bimestre, file, disciplinaId) {
    const formData = new FormData();
    formData.append("file", file);

    const response = await Axios().post(
      `planos-bimestrais/uploadPdf/${gestaoDeAulaId}/${bimestre}/${disciplinaId}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    const resultData = response.data ?? response;
    if (resultData) {
      return resultData;
    }
    return null;
  }

  async planoBimestralDownload(gestaoDeAulaId, bimestre, disciplinaId) {
    const response = await Axios()
      .get(`planos-bimestrais/downloadPdf/${gestaoDeAulaId}/${bimestre}/${disciplinaId}`, {
        responseType: "blob",
      })
      .then((response) => {
        const a = document.createElement("a");
        a.href = window.URL.createObjectURL(response.data);
        window.open(a.href);
      });

    return response;
  }

  async pdfAnexado(planoId) {
    const base64 = await Axios()
      .get(`planos-bimestrais/${planoId}/relatorioAnexado`, { responseType: "arraybuffer" })
      .then((response) => Buffer.from(response.data, "binary").toString("base64"));
    return `data:application/pdf;base64, ${base64}`;
  }
}
export default new PlanosBimestraisService();
