import Axios from "@/plugins/Axios";

export class SistemaBNCCService {
  async syncAll(matricula) {
    const response = await Axios().post("sistema-bncc", { id: matricula });
    const payload = response.data;
    const { resumos } = payload;
    const notas = Array.isArray(payload.notas) ? {} : payload.notas;
    payload.sistema.sistema_b_n_c_c.children.map((parent) => {
      return parent.children.map((sistemaBNCC) => {
        if (!notas[sistemaBNCC.id]) {
          notas[sistemaBNCC.id] = {
            matricula_id: payload.matricula.id,
            sistema_bncc_id: sistemaBNCC.id,
          };
        }
        return sistemaBNCC;
      });
    });
    return { ...payload, notas, resumos };
  }

  async listarTodos() {
    const response = await Axios().get("sistema-bncc");
    return response.data;
  }

  async createOrUpdate(descritivo, matricula_id, semestre) {
    const response = await Axios().post("sistema-bncc/criar-relatorio-descritivo", {
      descritivo,
      matricula_id,
      semestre,
    });
    return response.data;
  }

  async getGestaoRelatorioDescritivo(matricula_id) {
    const response = await Axios().get(`sistema-bncc/gestao-relatorio-descritivo/${matricula_id}`);
    return response.data;
  }

  async salvarNotas(matricula, notas) {
    await Axios().patch(`sistema-bncc/${matricula}`, notas);
  }

  async relatorioDescritivoPDF(matricula_id, semestre, file) {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await Axios().post(
        `sistema-bncc/uploadPdf/${matricula_id}/${semestre}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const resultData = response.data ?? response;
      if (resultData) {
        return resultData;
      }
      return null;
    } catch (error) {
      throw error;
    }
  }

  async relatorioDescritivoDownload(matricula_id, semestre) {
    const response = await Axios()
      .get(`sistema-bncc/downloadPdf/${matricula_id}/${semestre}`, {
        responseType: "blob",
      })
      .then((response) => {
        const a = document.createElement("a");
        a.href = window.URL.createObjectURL(response.data);
        window.open(a.href);
      });

    return response;
  }
}
export default new SistemaBNCCService();
