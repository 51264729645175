<template>
  <main-template>
    <v-row>
      <v-col>
        <span class="text-h4">Anos </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="pt-0 pb-0">
        <div class="d-flex mb-2 justify-space-between">
          <v-btn color="success" @click="criarSerie" class="mr-2">Criar ano</v-btn>
          <v-text-field
            v-model="search"
            class="ml-2 search-input"
            append-icon="mdi-magnify"
            label="Pesquisar..."
            solo
            autofocus
            dense
            single-line
            hide-details
          />
        </div>
      </v-col>
      <v-col class="pt-0">
        <v-data-table
          :headers="table.headers"
          :items="seriesList"
          :loading="carregandoSeries"
          :items-per-page="10"
          :custom-filter="dataTableFilter"
          :search="search"
          hide-default-footer
          class="elevation-1"
        >
          <template v-slot:body="{ items }">
            <tbody name="scroll-x-transition" is="transition-group" duration="150">
              <tr
                color="primary"
                :class="serie.estaDeletado ? ['deleted'] : []"
                v-for="serie of items"
                :key="serie.id"
              >
                <td>{{ serie.id }}</td>
                <td>{{ serie.ano ? serie.ano.descricao : "- - -" }}</td>
                <td>{{ serie.descricao }}</td>
                <!-- <td>{{ serie.contexto }}</td> -->
                <td v-if="!serie.estaDeletado">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="blue-grey lighten-3"
                        v-bind="attrs"
                        v-on="on"
                        @click="() => editarSerie(serie)"
                      >
                        <v-icon small>fa fa-edit </v-icon>
                      </v-btn>
                    </template>
                    <span>Editar serie</span>
                  </v-tooltip>
                  <v-tooltip color="error" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="error"
                        v-bind="attrs"
                        v-on="on"
                        @click="() => deletarSerie(serie)"
                      >
                        <v-icon small>fa fa-trash </v-icon>
                      </v-btn>
                    </template>
                    <span>Apagar serie</span>
                  </v-tooltip>
                </td>
                <td v-if="serie.estaDeletado">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="info"
                        v-bind="attrs"
                        v-on="on"
                        @click="() => recuperarSerie(serie)"
                      >
                        <v-icon small>fa fa-sync </v-icon>
                      </v-btn>
                    </template>
                    <span>Recuperar serie</span>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>

        <e-paginate
          :current_page="paginate.current_page"
          :last_page="paginate.last_page"
          :pageNumber="pageNumber"
          @changeActionResponse="listarSeries"
          :search="search"
        ></e-paginate>
      </v-col>
    </v-row>
  </main-template>
</template>

<script>
import { mapGetters } from "vuex";
import { dataTableFilter } from "@/plugins/searchQuery";
import Serie from "@/Models/Serie";

export default {
  // computed: {
  //   ...mapGetters("Series", ["seriesList", "carregandoSeries"]),
  // },
  data() {
    return {
      dataTableFilter,
      search: "",
      pageNumber: 0,
      paginate: {
        current_page: 0,
        last_page: 0,
      },
      seriesList: [],
      table: {
        headers: [
          { text: "#", value: "id" },
          // { text: "Código", value: "codigo" },
          { text: "Ano", value: "ano" },
          { text: "Nome", value: "descricao" },
          // { text: "Contexto", value: "contexto" },
          { text: "Ações", value: "actions", sortable: false },
        ],
      },
    };
  },
  mounted() {
    this.carregandoSeries = true;
    this.$store.dispatch("Series/loadSeries");
    this.listarSeries(this.pageNumber);
    this.carregandoSeries = true;
  },
  watch: {
    carregandoSeries(value) {
      if (value && this.seriesList.length === 0) {
        this.$loaderService.open("Carregando series");
      } else {
        this.$loaderService.close();
      }
    },
    async search(val) {
      if (val.length > 0) {
        await this.listarSeries(0, val);
      }
      if (!val) {
        await this.listarSeries(0);
      }
    },
  },
  methods: {
    criarSerie() {
      this.$router.push({ name: "series.create" });
    },
    async listarSeries(pageNumber, query = null) {
      this.carregandoSeries = true;
      try {
        const response = await this.$services.seriesService.syncAllPaginated(pageNumber, query);
        this.seriesList = await response.data.map((serie) => new Serie(serie));
        this.paginate = response;
      } catch (error) {
        this.$handleError(error);
      }
      this.carregandoSeries = false;
    },
    editarSerie(serie) {
      this.$router.push({ name: "series.edit", params: { serie_id: serie.id } });
      // return serie;
    },
    async deletarSerie(serie) {
      this.$modals.danger({
        title: "Cuidado!",
        message: "Tem certeza que deseja apagar esta serie?",
        confirmationCode: serie.id,
        confirmationMessage: `Por favor, digite <strong>${serie.id}</strong> para confirmar`,
        buttons: [
          {
            text: "Cancelar",
            callback: () => {
              this.$modals.close();
            },
            props: {
              color: "warning",
              small: true,
            },
          },
          {
            text: "Confirmar",
            disableIfCodeDoesntMatch: true,
            callback: async () => {
              this.$modals.close();
              this.$loaderService.open("Deletando a Serie");
              try {
                await this.$services.seriesService.deletarSerie(serie);
                this.$toast.success("Serie deletada com sucesso");
                this.listarSeries();
              } catch (error) {
                this.$handleError(error);
              }
              this.$loaderService.close();
            },
            props: {
              color: "error",
              // text: true,
              small: true,
            },
          },
        ],
      });
    },
    recuperarSerie(serie) {
      this.$modals.warn({
        title: "Atenção!",
        message: "Tem certeza que deseja recuperar esta serie?",
        confirmationCode: serie.id,
        confirmationMessage: `Por favor, digite <strong>${serie.id}</strong> para confirmar`,
        buttons: [
          {
            text: "Cancelar",
            callback: () => {
              this.$modals.close();
            },
            props: {
              color: "warning",
              small: true,
            },
          },
          {
            text: "Confirmar",
            disableIfCodeDoesntMatch: true,
            callback: async () => {
              this.$modals.close();
              this.$loaderService.open("Recuperando a Serie");
              try {
                await this.$services.seriesService.recuperarSerie(serie);
                this.$toast.success("Serie recuperada com sucesso");
                this.listarSeries();
              } catch (error) {
                this.$handleError(error);
              }
              this.$loaderService.close();
            },
            props: {
              color: "error",
              // text: true,
              small: true,
            },
          },
        ],
      });
    },
  },
};
</script>

<style></style>
